import * as React from 'react'
import { CssBaseline, ThemeProvider, type PaletteOptions, createTheme } from '@mui/material'

const palette: PaletteOptions = {
  background: { default: '#e1e1e1' },
  primary: {
    main: '#0288D1',
    light: '#03A9F4',
    dark: '#01579B',
    contrastText: '#fff'
  },
  secondary: {
    light: '#e3e3e3',
    dark: '#bdbdbd',
    main: '#d0d0d0'
  }
}

export const RootWrapper = ({ children }: React.PropsWithChildren): JSX.Element => {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={createTheme({ palette })}>{children}</ThemeProvider>
    </>
  )
}
