import React from 'react'
import { PageWrapper } from './src/page-wrapper'
import { RootWrapper } from './src/root-wrapper'
import '@fontsource/roboto'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <PageWrapper {...props}>{element}</PageWrapper>
}

export const wrapRootElement = ({ element }) => {
  return <RootWrapper>{element}</RootWrapper>
}
