import {
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import * as React from 'react'
import { Link } from 'gatsby'
import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Person'
import MailIcon from '@mui/icons-material/Email'
import BookIcon from '@mui/icons-material/MenuBook'
import { type FunctionComponent, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

interface PageProps {
  location: Location
  data: ImageData
  children: React.ReactNode
}

export const PageWrapper: FunctionComponent<PageProps> = ({ children, location }) => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const [drawerOpen, setDrawerOpen] = useState(false)

  let title = 'Candace Wheeler, Mississippi Author'
  if (isXs) {
    title = 'Candace Wheeler, Author'
  }

  const currentTab = React.useMemo(() => {
    if (location.pathname.includes('/cradle-in-the-oak')) {
      return 1
    } else if (location.pathname.includes('/squall-in-the-gulf')) {
      return 2
    } else if (location.pathname.includes('/sunset-in-the-sound')) {
      return 3
    } else if (location.pathname.includes('/contact')) {
      return 4
    }
    return 0
  }, [location])

  function toggleDrawer(): void {
    setDrawerOpen(!drawerOpen)
  }

  function closeDrawer(): void {
    setDrawerOpen(false)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'none',
            lg: 'none',
            xl: 'none'
          }
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open menu"
            edge="start"
            onClick={toggleDrawer}
            sx={{
              display: {
                xs: 'block',
                sm: 'block',
                md: 'none',
                lg: 'none',
                xl: 'none'
              }
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography align="center" variant="h6" style={{ width: '100%' }}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={closeDrawer}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        <List>
          <ListItemButton component={Link} to="/" selected={location.pathname === '/'} onClick={closeDrawer}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Author" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/cradle-in-the-oak"
            selected={location.pathname.includes('/cradle-in-the-oak')}
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <BookIcon />
            </ListItemIcon>
            <ListItemText primary="Cradle in the Oak" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/squall-in-the-gulf"
            selected={location.pathname.includes('/squall-in-the-gulf')}
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <BookIcon />
            </ListItemIcon>
            <ListItemText primary="Squall in the Gulf" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/sunset-in-the-sound"
            selected={location.pathname.includes('/sunset-in-the-sound')}
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <BookIcon />
            </ListItemIcon>
            <ListItemText primary="Sunset in the Sound" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/contact"
            selected={location.pathname.includes('/contact')}
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Contact" />
          </ListItemButton>
        </List>
      </Drawer>
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          backgroundColor: theme.palette.background.default
        }}
        component="main"
      >
        <Box
          sx={{
            ...theme.mixins.toolbar,
            display: {
              xs: 'block',
              sm: 'block',
              md: 'none',
              lg: 'none',
              xl: 'none'
            }
          }}
        />
        <Container disableGutters>
          <StaticImage
            src="./images/site-cover.jpg"
            alt={title}
            style={{
              display: 'block!important',
              minHeight: 150
            }}
            layout="constrained"
            loading="eager"
            quality={100}
          />
          <AppBar
            position="relative"
            sx={{
              zIndex: 1,
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block'
              }
            }}
          >
            <Tabs value={currentTab} centered textColor="inherit" indicatorColor="secondary">
              <Tab component={Link} to="/" label="Author" id="tab-0" />
              <Tab component={Link} to="/cradle-in-the-oak" label="Cradle in the Oak" id="tab-1" />
              <Tab component={Link} to="/squall-in-the-gulf" label="Squall in the Gulf" id="tab-2" />
              <Tab component={Link} to="/sunset-in-the-sound" label="Sunset in the Sound" id="tab-3" />
              <Tab component={Link} to="/contact" label="Contact" />
            </Tabs>
          </AppBar>
        </Container>
        <Box paddingY={2}>{children}</Box>
      </Box>
    </Box>
  )
}
